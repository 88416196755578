<template>
  <el-menu
    style="display: inline-block; margin-left: 10px"
    mode="horizontal"
    router
    :default-active="$route.path"
  >

    <el-menu-item
      index="/"
      :route="{name: 'Home'}"
    >
      Главная
    </el-menu-item>

    <el-submenu
      v-show="showAdministration"
      index="1"
    >
      <template slot="title">Администрирование</template>

      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.RBAC.LIST)"
        index="/settings/rbac"
        :route="{name: 'Rbac'}"
      >
        RBAC
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.USER.CHIEFS_LIST_BY_TYPE)"
        index="/settings/chiefs"
        :route="{name: 'Chiefs'}"
      >
        Оргструктура
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.GROUP.LIST)"
        index="/settings/groups"
        :route="{name: 'Groups'}"
      >
        Группы
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.MASS_ACTIONS.CREATE)"
        index="/settings/mass-actions"
        :route="{name: 'MassActions'}"
      >
        Массовые действия
      </el-menu-item>
<!--      <el-menu-item-->
<!--        v-show="$canAndRulesPass(user, $permissions.SETTING.UPDATE)"-->
<!--        index="/settings/dynamic"-->
<!--        :route="{name: 'DynamicSettings'}"-->
<!--      >-->
<!--        Прочее-->
<!--      </el-menu-item>-->
    </el-submenu>

    <el-submenu
      v-show="showCatalogs"
      index="3"
    >
      <template slot="title">Справочники</template>

      <el-menu-item
        v-show="$canAndRulesPass(user, 'scales.see_units')"
        index="/catalog/units"
        :route="{name: 'Units'}"
      >
        Единицы измерения
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, 'evaluations.see_in_menu')"
        index="/catalog/evaluations"
        :route="{name: 'Evaluations'}"
      >
        Оценки
      </el-menu-item>
    </el-submenu>


    <el-submenu
      v-show="$canAndRulesPass(user, $permissions.LIBRARY.SEE_IN_MENU)"
      index="2"
    >
      <template slot="title">Библиотеки</template>

      <el-menu-item
        v-for="(typeConfig, typeKey) in $companyConfiguration.libraries.getTypes()"
        :key="typeKey"
        :index="'/library/'+typeKey"
        :route="{name: 'Library', params: { type: typeKey }}"
      >
        {{ typeConfig.showName }}
      </el-menu-item>
    </el-submenu>

    <el-submenu
      v-show="$canAndRulesPass(user, $permissions.CARD.LIST) || $canAndRulesPass(user, 'calibration.see_in_menu')"
      index="4"
    >
      <template slot="title">Управление эффективностью и карьерой</template>

      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.CARD.LIST)"
        index="/cards"
        :route="{name: 'Cards'}"
      >
        Карты целей
      </el-menu-item>

      <el-menu-item
        v-show="$canAndRulesPass(user, 'calibration.see_in_menu')"
        index="/calibration"
        :route="{name: 'Calibration'}"
      >
        Калибровка оценок
      </el-menu-item>
    </el-submenu>

    <el-menu-item
      v-show="$canAndRulesPass(user, $permissions.MATRIX.VIEW)"
      index="/matrix"
      :route="{name: 'Matrix'}"
    >
      Матрица КПЭ
    </el-menu-item>
    <el-menu-item
      v-show="$canAndRulesPass(user, $permissions.USER.ORGSTRUCT_VIEW)"
      index="/orgstruct"
      :route="{name: 'Orgstruct'}"
    >
      Орг. структура
    </el-menu-item>
    <el-menu-item
      v-show="$canAndRulesPass(user, $permissions.REPORT.USE)"
      index="/report"
      :route="{name: 'Report'}"
    >
      Конструктор отчетов
    </el-menu-item>

  </el-menu>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "menu-component",
  components: {},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
    showAdministration(){
      return this.$canAndRulesPass(this.user, this.$permissions.RBAC.LIST)
          || this.$canAndRulesPass(this.user, this.$permissions.USER.CHIEFS_LIST_BY_TYPE)
          || this.$canAndRulesPass(this.user, 'setting.scales')
          || this.$canAndRulesPass(this.user, this.$permissions.GROUP.LIST)
          || this.$canAndRulesPass(this.user, this.$permissions.MASS_ACTIONS.CREATE)
          || this.$canAndRulesPass(this.user, this.$permissions.SETTING.UPDATE)
    },
    showCatalogs(){
      return this.$canAndRulesPass(this.user, 'scales.see_units')
          || this.$canAndRulesPass(this.user, 'evaluations.see_in_menu')
    },
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>


<style lang="scss">

</style>